<main class="post-view" *ngIf="mode != 'pubnub'">
  <app-offer-info
    *ngIf="currentOffer"
    [offer]="currentOffer"
    [isInvestor]="isInvestor"
  ></app-offer-info>

  <section class="post-view__buyers" *ngIf="!isInvestor && currentOffer">
    <div *ngIf="mode === 'offers'" class="post-view__seller-buttons d-flex mx-3">
      <h1 class="ml-2 mr-3">Bids</h1>
      <a [routerLink]="'/edit-listing/' + currentOffer.listing.id" class="post-view__edit"
        >EDIT LISTING</a
      >
      <a class="post-view__delete" (click)="deleteListing(currentOffer.listing.id)"
        >DELETE LISTING</a
      >
    </div>
    <div class="post-view__sorting">
      <div
        [class.active]="offersOrder === 'newest'"
        id="newest"
        class="post-view__sorting-item"
        (click)="changeOrder('newest')"
      >
        NEWEST
      </div>
      <div
        [class.active]="offersOrder === 'oldest'"
        id="oldest"
        class="post-view__sorting-item"
        (click)="changeOrder('oldest')"
      >
        OLDEST
      </div>
      <div
        [class.active]="offersOrder === 'highest-offer'"
        id="highest-offer"
        class="post-view__sorting-item"
        (click)="changeOrder('highest-offer')"
      >
        HIGHEST OFFER
      </div>
    </div>
    <div class="post-view__list hide-scrollbar">
      <ng-container *ngFor="let offer of investors$ | async | offersOrderPipe: offersOrder">
        <a
          [routerLink]="'/offer/' + offer.id"
          [class.active]="offerID === offer.id"
          (click)="changeMode('pubnub')"
          class="post-view__buyers-block"
        >
          <img
            [src]="offer.buyer.photo || '../../../images/icons/avatar.png'"
            alt=""
            class="post-view__buyers-photo"
          />
          <div class="post-view__buyers-text">
            <div class="post-view__buyers-value">
              {{ offer.price ? '$' + offer.price : 'No Bid Added' }}
            </div>
            <span class="post-view__buyers-name"
              >{{ offer.buyer.first_name }} {{ offer.buyer.last_name }}</span
            >
          </div>
          <div class="buyers-status">
            <div class="blue-box" *ngIf="unreadMessages.has(offer.id)"></div>
            <div class="post-view__buyers-rating">
              {{ offer.buyer.rating || 0 | number: '1.1-1' }} Rating
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </section>
  <span class="d-none">{{ investors$ | async }}</span>
  <pubnub
    class="flex-grow-1 post-view__chat"
    *ngIf="currentOffer && (mode != 'offers' || isInvestor)"
    [offer]="currentOffer"
    [mobileMode]="mode !== 'desktop'"
    [investors$]="investors$"
    [isInvestor]="isInvestor"
    [user]="user"
    (newMessages)="updateNewMessage($event)"
    (refreshData)="refreshDataHandler($event)"
  ></pubnub>
</main>
<ng-container *ngIf="currentOffer && mode === 'pubnub'">
  <div class="breadcrumbs" itemscope="" itemtype="http://schema.org/BreadcrumbList">
    <ul class="breadcrumbs__list ul-reset">
      <li
        class="breadcrumbs__item"
        itemscope=""
        property="itemListElement"
        itemtype="http://schema.org/ListItem"
      >
        <a (click)="changeMode('offers')" itemprop="item" class="breadcrumbs__link">
          <span itemprop="name">{{ 'BACK TO OFFERS' }}</span>
        </a>
        <meta itemprop="position" content="1" />
      </li>
    </ul>
  </div>
  <pubnub
    class="flex-grow-1 post-view__chat"
    [offer]="currentOffer"
    [investors$]="investors$"
    [isInvestor]="isInvestor"
    [mobileMode]="mode !== 'desktop'"
    [user]="user"
    (newMessages)="updateNewMessage($event)"
    (refreshData)="refreshDataHandler($event)"
  ></pubnub>
</ng-container>
