export const environment = {
  production: true,
  apiUrl: 'https://dealup-stage.emphasoft.com/api/',
  facebookClientId: '429273661485333',
  googleClientId: '821540941995-m71qs18k3c035avha1937anamnnjggvd.apps.googleusercontent.com',
  sentryDSN: 'https://ff2fe406fee74a9bbdc969310f5e4cd3@o118529.ingest.sentry.io/5682461',
  sentryEnvName: 'stage',
  pubNubPublish: 'pub-c-3e81ed53-db75-46b4-b7a5-baaaeb8d2f24',
  pubNubSubscribe: 'sub-c-bc760cde-87ea-11eb-88a7-4a59fc122af9',
  googleApiKey: 'AIzaSyC4pDKbHg2oR6qHC91CiFMzXlIDm-M_kJw',
};
